import { locales } from './locales';
import type { TranslationsDictionary, Locale } from './types';

interface Module {
  default: TranslationsDictionary;
}

interface LocaleFunctions {
  [key: string]: () => Promise<TranslationsDictionary>;
}

const localeDictionaries: { [key: string]: () => Promise<TranslationsDictionary> } = locales.reduce(
  (acc: LocaleFunctions, site: Locale) => {
    acc[site.id] = () => import(`@lib/i18n/${site.id}.json`).then((module: Module) => module.default);

    return acc;
  },
  {}
);

export async function getLocaleDictionary(locale: string): Promise<TranslationsDictionary> {
  const localeId = locales.find((site) => site.path === locale)?.id ?? 'es-ES';

  return localeDictionaries[localeId]?.() || {};
}
