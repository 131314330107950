import { useState } from 'react';
import type { TouchEvent } from 'react';

interface UseSwipeParams {
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: () => void;
}

const MIN_SWIPE_DISTANCE_IN_PX = 50;

export function useSwipe({ onSwipeLeft, onSwipeRight }: UseSwipeParams): SwipeOutput {
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);

  const onTouchStart = (e: TouchEvent): void => {
    setTouchEndX(0);
    setTouchStartX(e.targetTouches[0]?.clientX ?? 0);

    setTouchEndY(0);
    setTouchStartY(e.targetTouches[0]?.clientY ?? 0);
  };

  const onTouchMove = (e: TouchEvent): void => {
    setTouchEndX(e.targetTouches[0]?.clientX ?? 0);
    setTouchEndY(e.targetTouches[0]?.clientY ?? 0);
  };

  const onTouchEnd = (): void => {
    const distanceX = touchStartX - touchEndX;
    const distanceY = touchStartY - touchEndY;
    const isLeftSwipe = distanceX > MIN_SWIPE_DISTANCE_IN_PX;
    const isRightSwipe = distanceX < -MIN_SWIPE_DISTANCE_IN_PX;

    if (isLeftSwipe && distanceX > distanceY) {
      onSwipeLeft?.();
    }

    if (isRightSwipe && Math.abs(distanceX) > distanceY) {
      onSwipeRight?.();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
