'use client';

import Link from 'next/link';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { urlForImage } from '@lib/sanity/image';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  data?: {
    bgColor?: string;
    headline?: string;
    posts?: {
      _key: string;
      excerpt: string;
      mainImage?: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
      slug: {
        current: string;
      };
      title: string;
    }[];
  };
  options?: {
    titleVariation: 'center' | 'left';
    showMoreButton?: boolean;
  };
  translationsDictionary: TranslationsDictionary;
};

export default function PostsLatest({ data, options, translationsDictionary }: Props): JSX.Element | null {
  const t = useTranslation(translationsDictionary);

  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-6 sm:py-8 lg:py-14 ${
        data?.bgColor ?? 'bg-white'
      }`}
    >
      <div className="flex flex-col w-full items-center px-4 md:px-10 lg:px-16">
        <h3
          className={`w-full pb-6 lg:pb-12 font-display text-[1.75rem] text-black leading-7 lg:leading-10 text-left text-center max-w-screen-lg ${options?.titleVariation === 'center' ? 'lg:text-[2rem] font-medium' : 'lg:text-left lg:text-4xl font-semibold'}`}
        >
          {data?.headline}
        </h3>
        {data?.posts?.length ? (
          <Swiper
            breakpoints={{
              520: {
                pagination: {
                  enabled: data.posts.length > 2,
                },
                slidesPerView: data.posts.length >= 2 ? 2 : data.posts.length,
                spaceBetween: 20,
              },
              1040: {
                pagination: {
                  enabled: data.posts.length > 3,
                },
                slidesPerView: data.posts.length >= 3 ? 3 : data.posts.length,
                spaceBetween: 24,
              },
            }}
            className={`w-full ${data.posts.length === 1 ? 'max-w-[560px]' : ''}${
              data.posts.length === 2 ? 'max-w-[960px]' : ''
            }${data.posts.length > 2 ? 'max-w-screen-lg' : ''}`}
            loop={false}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
            resizeObserver
            slidesPerView={1}
            spaceBetween={30}
          >
            {data.posts.map((post) => (
              <SwiperSlide className="" key={post.title}>
                <Card className="w-full max-w-[30rem] px-4 pt-3 pb-5 lg:p-5 rounded-lg" elevation={0}>
                  <Link href={`/blog/${post.slug.current}`}>
                    <CardMedia
                      className="h-[11rem] lg:h-[12rem] rounded-lg"
                      image={post.mainImage ? urlForImage(post.mainImage).url() : ''}
                      title={post.title}
                    />
                  </Link>
                  <CardContent className="min-h-[9rem] lg:min-h-[9.5rem] px-0 !pb-0">
                    <h4 className="pb-2 lg:pb-3">
                      <Link
                        className="text-2xl lg:text-[1.625rem] text-black font-display font-normal leading-[1.875rem] lg:leading-8 line-clamp-2 hover:no-underline"
                        href={`/blog/${post.slug.current}`}
                      >
                        {post.title}
                      </Link>
                    </h4>
                    <div className="text-sm text-black font-light leading-5 line-clamp-3">{post.excerpt}</div>
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
        {options?.showMoreButton !== false ? (
          <Button className="mt-3" color="primary" disableElevation href="/blog" variant="contained">
            {t('latestPosts.readMore')}
          </Button>
        ) : null}
      </div>
    </section>
  );
}
