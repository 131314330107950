import { Suspense } from 'react';
import ConsentModal from './consent-modal';
import ConfigModal from './config-modal';
import Script from './script';
import { GtmProvider } from './context';
import type { GtmConfig } from './types';

type Props = {
  children: React.ReactNode;
  config?: GtmConfig;
};

export function Gtm({ children, config }: Props): JSX.Element {
  if (!config?.gtmContainerId) {
    return <>{children}</>;
  }

  return (
    <GtmProvider>
      <Suspense>
        <Script containerId={config.gtmContainerId} />
      </Suspense>
      <ConsentModal config={config} />
      <ConfigModal config={config} />
      {children}
    </GtmProvider>
  );
}

export * from './context';
export * from './use-gtm-event';
export type { EventArguments } from './types';
