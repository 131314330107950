import type { Locale, Rewrite } from './types';

/**
 * Array of sites/languages that are supported.
 * A site can be a domain or a directory (e.g. /es/ or fuca.es)
 */
export const locales: Locale[] = [{ id: 'es-ES', title: 'España', path: 'es', isDefault: true }];

/**
 * Array of rewrites to be applied in the middleware
 */
export const rewrites: Rewrite[] = [
  { source: '/productos/', destination: '/es/products/' },
  { source: '/es/productos/', destination: '/es/products/' },
];
