import { useEffect, useState } from 'react';

function getWindowScroll(): { scrollX: number; scrollY: number } {
  if (typeof window === 'undefined') {
    return {
      scrollX: 0,
      scrollY: 0,
    };
  }

  const { scrollX, scrollY } = window;

  return {
    scrollX,
    scrollY,
  };
}

export function useWindowScroll(): {
  scrollX: number;
  scrollY: number;
} {
  const [windowSize, setWindowSize] = useState(getWindowScroll());

  useEffect(() => {
    function handleResize(): void {
      setWindowSize(getWindowScroll());
    }

    if (typeof window === 'undefined') return;

    window.addEventListener('scroll', handleResize);

    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, []);

  return windowSize;
}
