'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useHoverIntent } from 'ui/hooks';

export type MenuItemType = {
  label: string;
  href: string;
  dropdown?: (isVisible: boolean) => JSX.Element;
};

type MenuItemProps = {
  className?: string;
  item: MenuItemType;
};

export default function MenuItem({ className, item }: MenuItemProps): JSX.Element {
  const pathname = usePathname();
  const [isNavItemHovered, intentRef, setIsNavItemHovered] = useHoverIntent({ sensitivity: 100 });
  const isActive = pathname.startsWith(item.href);

  return (
    <li
      className={`lg:pt-[0.375rem] ${className ? className : ''}`}
      key={item.label}
      onBlur={() => {
        setIsNavItemHovered(false);
      }}
      onFocus={() => {
        setIsNavItemHovered(true);
      }}
      ref={intentRef}
    >
      <Link
        className={`relative flex items-center h-full lg:px-6 hover:no-underline rounded focus-visible:outline focus-visible:no-underline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[2px] lg:focus:no-underline lg:focus:outline-0 ${
          isNavItemHovered || isActive
            ? `text-primary lg:after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-1 after:bg-primary`
            : 'text-inherit'
        } ${isActive ? 'font-semibold' : ''}`}
        href={item.href}
        onClick={() => {
          document.body.classList.remove('no-scroll');
        }}
      >
        {item.label}
      </Link>
      {item.dropdown ? item.dropdown(isNavItemHovered) : null}
    </li>
  );
}
