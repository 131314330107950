import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export function useComponentOnScreen<T extends HTMLElement>(
  ref: RefObject<T>,
  options?: IntersectionObserverInit
): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (ref.current && typeof window !== 'undefined') {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry) {
          setIsIntersecting(entry.isIntersecting);
        }
      }, options);

      const currentRef = ref.current;
      observer.observe(currentRef);

      return () => {
        observer.unobserve(currentRef);
      };
    }
  }, [ref, options]);

  return isIntersecting;
}
