import { useState, useEffect, useImperativeHandle, useRef } from 'react';
import type { Dispatch, SetStateAction } from 'react';

interface OptionType {
  ref?: React.RefObject<HTMLElement | null>;
  sensitivity?: number;
  interval?: number;
  timeout?: number;
}

export const useHoverIntent = <T>(
  options?: OptionType
): [boolean, React.RefObject<HTMLElement & HTMLLIElement & T>, Dispatch<SetStateAction<boolean>>] => {
  const { ref, sensitivity = 6, interval = 100, timeout = 0 } = options ?? {};
  const intentRef = useRef<HTMLElement & HTMLLIElement & T>(null);
  const [isHovering, setIsHovering] = useState(false);

  let x = 0,
    y = 0,
    pX = 0,
    pY = 0,
    timer = 0;
  const delay = (): void => {
    if (timer) {
      clearTimeout(timer);
    }

    setIsHovering(false);
  };
  const tracker = (e: MouseEvent): void => {
    x = e.clientX;
    y = e.clientY;
  };
  const compare = (e: MouseEvent): void => {
    if (timer) {
      clearTimeout(timer);
    }

    if (Math.sqrt((pX - x) * (pX - x) + (pY - y) * (pY - y)) < sensitivity) {
      setIsHovering(true);

      return;
    }

    pX = x;
    pY = y;
    timer = window.setTimeout(() => {
      compare(e);
    }, interval);
  };
  const dispatchOver = (e: MouseEvent): void => {
    if (timer) {
      clearTimeout(timer);
    }

    if (intentRef.current) {
      intentRef.current.removeEventListener('mousemove', tracker, false);
    }

    if (!isHovering) {
      pX = e.clientX;
      pY = e.clientY;

      if (intentRef.current) {
        intentRef.current.addEventListener('mousemove', tracker, false);
      }

      timer = window.setTimeout(() => {
        compare(e);
      }, interval);
    }
  };
  const dispatchOut = (): void => {
    if (timer) {
      clearTimeout(timer);
    }

    if (intentRef.current) {
      intentRef.current.removeEventListener('mousemove', tracker, false);
    }

    if (isHovering) {
      timer = window.setTimeout(() => {
        delay();
      }, timeout);
    }
  };

  useEffect(() => {
    const currentRef = intentRef.current;

    if (currentRef) {
      currentRef.addEventListener('mouseover', dispatchOver, false);
      currentRef.addEventListener('mouseout', dispatchOut, false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (currentRef) {
        currentRef.removeEventListener('mouseover', dispatchOver, false);
        currentRef.removeEventListener('mouseout', dispatchOut, false);
      }
    };
  });

  useImperativeHandle(ref, () => intentRef.current, [intentRef]);

  return [isHovering, intentRef, setIsHovering];
};
