import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from './use-window-size';

interface UseResizableParams {
  defaultWidth: number;
  minWidth?: number;
  maxWidth?: number;
  storageKey?: string;
  isRightToLeft?: boolean;
}

export function useResizable({
  defaultWidth,
  minWidth = 0,
  maxWidth = 2000,
  storageKey = '',
  isRightToLeft = false,
}: UseResizableParams): [React.RefObject<HTMLElement>, number, () => void, () => void] {
  const ref = useRef<HTMLElement>(null);
  const isInitialRender = useRef<boolean>(true);
  const [isResizing, setIsResizing] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const initialWidth = parseInt(localStorage.getItem(storageKey) ?? '0') || defaultWidth;
  const [elementWidth, setElementWidth] = useState(initialWidth);

  const handleResize = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    document.body.classList.remove('is-resizing');
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: globalThis.MouseEvent) => {
      if (isResizing) {
        const resizeHandleX = mouseMoveEvent.clientX;
        const adjustedX = isRightToLeft ? windowWidth - resizeHandleX : resizeHandleX;
        const boundedMaxWidth = adjustedX > maxWidth ? maxWidth : adjustedX;
        const boundedX = adjustedX < minWidth ? minWidth : boundedMaxWidth;

        setElementWidth(boundedX);
        document.body.classList.add('is-resizing');

        if (storageKey) {
          try {
            localStorage.setItem(storageKey, String(boundedX));
          } catch (_error) {
            // ignore, localStorage might be disabled
          }
        }
      }
    },
    [isResizing, isRightToLeft, maxWidth, minWidth, storageKey, windowWidth]
  );

  const reset = useCallback(() => {
    setElementWidth(defaultWidth);

    if (storageKey) {
      try {
        localStorage.removeItem(storageKey);
      } catch (_error) {
        // ignore, localStorage might be disabled
      }
    }
  }, [defaultWidth, storageKey]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;

      return;
    }

    reset();
  }, [reset, windowWidth]);

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return [ref, elementWidth, handleResize, reset];
}
