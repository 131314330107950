import { getLocaleDictionary } from '@lib/i18n/dictionaries';
import type { TranslationsDictionary } from './types';

/**
 * This hook returns a language dictionary JSON object.
 * The hook accepts a "locale" parameter, which is used to determine the locale file to import (i.e. 'es')
 * The translation JSON object is lazy-imported from the respective locale file (i.e. /es-ES.json).
 */
export async function useTranslationsDictionary(locale: string): Promise<TranslationsDictionary> {
  const dictionary: TranslationsDictionary = await getLocaleDictionary(locale);

  return dictionary;
}
