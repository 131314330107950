import { useEffect, useRef } from 'react';

/**
 * Used to allow smooth scrolling to anchors.
 *
 * Usage:
 *
 * export default function FeaturesSection() \{
 *  const bind = useSmoothScrollTo('#features');
 *  return (
 *    \<section \{...bind\} className=\{classes.features\}\>
 *    ...
 *    </section>
 *  )
 * \}
 */
export function useSmoothScrollTo(id: string): {
  'data-anchor-id': string;
  ref: React.MutableRefObject<HTMLElement | null>;
} {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current && window.location.hash === id) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [id]);

  return {
    'data-anchor-id': id,
    ref,
  };
}
