'use client';

import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useWindowSize, useSwipe } from 'ui/hooks';
import LogoFuca from '@public/logos/fuca.svg';
import { useTranslation, type TranslationsDictionary } from '@lib/i18n';
import type { Settings } from '@lib/sanity/types';
import MenuItem from './menu-item';

export default function Header({ config, dict }: { config?: Settings; dict: TranslationsDictionary }): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const t = useTranslation(dict);
  const { width } = useWindowSize();
  const swipeHandler = useSwipe({
    onSwipeLeft: () => {
      setIsMenuOpen(false);
      unblockScrolling();
    },
  });
  const onEscapeKeyPressed = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setIsMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (width >= 1200) {
      setIsMenuOpen(false);
      unblockScrolling();

      return;
    }

    // prevents the mobile menu from flickering when resizing the window from desktop to mobile
    setIsMenuVisible(false);

    setTimeout(() => {
      setIsMenuVisible(true);
    }, 0);
  }, [width]);

  useEffect(() => {
    document.addEventListener('keydown', onEscapeKeyPressed, false);

    return () => {
      document.removeEventListener('keydown', onEscapeKeyPressed, false);
    };
  }, [onEscapeKeyPressed]);

  function toggleMenu(): void {
    setIsMenuOpen((prevState) => !prevState);

    if (isMenuOpen) {
      unblockScrolling();

      return;
    }

    blockScrolling();
  }

  function blockScrolling(): void {
    document.body.classList.add('no-scroll');
  }

  function unblockScrolling(): void {
    document.body.classList.remove('no-scroll');
  }

  return (
    <header className="fixed lg:relative flex w-full justify-center h-[3.4375rem] md:h-20 lg:h-[6.375rem] pl-1 pr-2 md:pl-6 md:pr-8 lg:px-16 transition-colors z-20 text-black bg-white font-display">
      <div className="flex lg:flex-nowrap w-full max-w-screen-lg">
        <IconButton
          aria-label={isMenuOpen ? t('header.closeMenu') : t('header.openMenu')}
          className="w-12 lg:!hidden z-[11] text-white rounded focus-visible:shadow-none focus-visible:outline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[-2px]"
          onClick={toggleMenu}
        >
          <MenuOutlinedIcon className="text-primary h-8 w-8" />
        </IconButton>
        <div className="flex basis-full lg:basis-24 grow shrink-1 lg:shrink-0 items-center">
          <Link
            aria-label={t('header.logoLinkAriaLabel')}
            className="flex justify-center items-center grow lg:grow-0 h-full rounded focus-visible:outline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[-2px] z-10"
            href="/"
          >
            <LogoFuca className="fill-white h-9 md:h-[2.375rem]" />
          </Link>
        </div>
        {isMenuVisible ? (
          <nav
            className={`fixed lg:static top-0 lg:top-auto lg:left-auto left-0 right-[8%] bottom-0 flex flex-col justify-start lg:justify-center grow basis-full lg:basis-auto order-last lg:order-none z-30 lg:z-auto transition ease-in delay-50 duration-200 lg:transition-none bg-white lg:bg-transparent ${
              isMenuOpen ? 'translate-x-0' : 'translate-x-[calc(-100%-4rem)] lg:transform-none'
            }`}
            {...swipeHandler}
          >
            <IconButton
              aria-label={t('header.closeMenu')}
              className="fixed top-2 right-1 w-12 lg:!hidden z-[11] text-white rounded focus-visible:shadow-none focus-visible:outline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[-2px]"
              onClick={() => {
                setIsMenuOpen(false);
              }}
            >
              <CloseOutlinedIcon className="text-black" />
            </IconButton>
            <Link
              aria-label={t('header.logoLinkAriaLabel')}
              className="lg:hidden block mx-9 mt-10 rounded focus-visible:outline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[3px]"
              href="/"
            >
              <LogoFuca className="fill-white h-9 md:h-[2.375rem]" />
            </Link>
            <ul className="flex flex-col grow lg:flex-row lg:justify-center w-full px-9 md:px-10 lg:px-0 py-8 lg:py-0 lg:w-auto text-xl lg:text-lg leading-7 lg:leading-6 gap-y-7 text-nowrap font-normal lg:font-medium overflow-y-auto">
              {config?.mainMenuItems?.map((item) => <MenuItem item={item} key={item.label} />)}
            </ul>
          </nav>
        ) : null}
        <div className="w-12 lg:w-auto lg:basis-24 grow shrink-0" />
      </div>
      <div
        aria-hidden="true"
        className="fixed inset-0 z-10 bg-overlay/60 data-[state=closed]:hidden lg:hidden data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
        data-state={isMenuOpen ? 'open' : 'closed'}
        onClick={() => {
          setIsMenuOpen(false);
        }}
        {...swipeHandler}
      />
    </header>
  );
}
