import { useEffect, useState } from 'react';

function getWindowSize(): { width: number; height: number } {
  if (typeof window === 'undefined') {
    return {
      width: 0,
      height: 0,
    };
  }

  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export function useWindowSize(): {
  width: number;
  height: number;
} {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize(): void {
      setWindowSize(getWindowSize());
    }

    if (typeof window === 'undefined') return;

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
