import type { Translation, TranslationsDictionary } from './types';

/**
 * This hook returns a function that can be used to get a translation string from an i18n JSON dictionary.
 * The hook accepts a "dictionary" parameter, which consists of an object of translations obtained by using the useTranslationsDictionary hook.
 * useTranslationsDictionary is an async function and can only be used in server-side component (usually a layout component)
 * Usage:
 * const t = await useTranslation(dictionary)
 * The function returned by the hook accepts a string ID (i.e. t('hello.world')).
 */
export function useTranslation(dictionary: TranslationsDictionary): Translation {
  return function translations(id): string {
    const parts = id.split('.');

    if (parts.length > 1) {
      // @ts-expect-error -- the accumulator can be a string or a dictionary. There is no easy way to type this in a reducer
      return parts.reduce((acc, part) => {
        if (acc[part]) {
          return acc[part];
        }

        return id;
      }, dictionary);
    }

    return (dictionary[id] as string) || id;
  };
}
